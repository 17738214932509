/* http://meyerweb.com/eric/tools/css/reset/
  v2.0 | 20110126
  License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: inherit;
}
*,
::after,
::before {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  src: url(./font/NotoSans/NotoSansKR-Regular.woff2) format("woff2"),
    url(./font/NotoSans/NotoSansKR-Regular.woff) format("woff");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  src: url(./font/NotoSans/NotoSansKR-Medium.woff2) format("woff2"),
    url(./font/NotoSans/NotoSansKR-Medium.woff) format("woff");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  src: url(./font/NotoSans/NotoSansKR-Bold.woff2) format("woff2"),
    url(./font/NotoSans/NotoSansKR-Bold.woff) format("woff");
}

@font-face {
  font-family: "BBTreeC_R";
  src: url("./font/BBTreeC/BBTreeCR.woff2") format("woff2"),
    url("./font/BBTreeC/BBTreeCR.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BBTreeC_B";
  src: url("./font/BBTreeC/BBTreeCB.woff2") format("woff2"),
    url("./font/BBTreeC/BBTreeCB.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* html,
body {
  height: 100%;
} */

body {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    sans-serif;
  font-size: 14px;
  color: black;
}

/* ::-webkit-scrollbar {
  display: none;
} */

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

button,
input {
  overflow: visible;
}

img {
  border-style: none;
}

:root {
  overflow-wrap: break-word;
  word-break: break-word;

  /* 2dub Color */

  /* coral */
  --coral005: #fff9fa;
  --coral010: #ffebee;
  --coral020: #ffccd1;
  --coral030: #fa9797;
  --coral040: #f46d6d;
  --coral045: #ff5f5f;
  --coral050: #ff4747;
  --coral055: #ed4747;
  --coral060: #ff2f27;
  --coral070: #f62229;
  --coral080: #e41023;
  --coral090: #d7001b;
  --coral100: #c7000d;

  /* blue */
  --blue005: #f6fcfe;
  --blue010: #e1f5fc;
  --blue020: #b3e6f6;
  --blue030: #81d6f1;
  --blue040: #50c5ec;
  --blue050: #27b9ea;
  --blue060: #00ace9;
  --blue070: #009edb;
  --blue080: #008cc8;
  --blue090: #007bb5;
  --blue100: #005b95;

  /* gray */
  --gray010: #fcfcfc;
  --gray020: #fafafa;
  --gray030: #f5f5f5;
  --gray040: #eeeeee;
  --gray050: #e0e0e0;
  --gray060: #9e9e9e;
  --gray070: #616161;
  --gray080: #424242;
  --gray090: #212121;
  --gray100: #000000;

  /* Video Language Color */
  --korean: #f67949;
  --english: #7684ff;

  /* Gradient */
  --gradient45: linear-gradient(135deg, #ff923c 0%, #ff4747 100%);
  --gradient_h: linear-gradient(90deg, #ff923c 0%, #ff4747 100%);
  --gradient_v: linear-gradient(0deg, #ff923c 0%, #ff4747 100%);

  /* background */
  --background: #f0f3f8;

  /* class Label */
  --Red: #ff5f5f;
  --Sky: #51b5ff;
  --Yellow: #ffaf16;
  --Green: #60e269;
  --Violet: #d166f8;
  --Orange: #f67949;
  --Blue: #7684ff;
  --Mint: #00dcca;
  --Brown: #b6693c;
  --gray: #999999;
  --Navy: #1e2c5f;

  --subGreen: #4fcb6b;
  --subYellow: #f8c030;
  --subYellow24: #fdf0cd;
  --subYellow40: #fce6ac;
  --subBlue: #1a75ff;
  --subBlue10: #e8f1ff;
  --subMagenta: #ff475d;

  /* box-shadow */
  --elevation_01dp: 0px 0px 1px rgba(0, 0, 0, 0.15),
    0px 3px 3px rgba(0, 0, 0, 0.08);
  --elevation_03dp: 0 3px 8px 0 rgba(0, 0, 0, 0.08),
    0 2px 5px 0 rgba(0, 0, 0, 0.03), 0 0 1px 0 rgba(0, 0, 0, 0.15);
  --elevation_04dp: 0px 0px 1px rgba(0, 0, 0, 0.15),
    0px 1px 5px rgba(0, 0, 0, 0.05), 0px 6px 8px rgba(0, 0, 0, 0.1);
  --elevation_06dp: 0px 6px 10px rgba(0, 0, 0, 0.14),
    0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px -1px rgba(0, 0, 0, 0.2);
  --elevation_08dp: 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
  --elevation_24dp: 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 11px 15px -7px rgba(0, 0, 0, 0.2);

  /* Class Color */
  --side-page: var(--side-nav);
  --PrimaryBasic: #7c6bff;
  --ErrorColor01: #f62229;
  --ErrorColor02: #e21b11;
  --PositiveColor: #514aff;
  --GrayscaleF8: #f8f8f8;
  --GrayscaleEE: #eeeeee;
  --GrayscaleDD: #dddddd;
  --GrayscaleCC: #cccccc;
  --Grayscale99: #999999;
  --Grayscale080: #616161;
  --Grayscale66: #666666;
  --Greyscale33: #333333;
  --GrayscaleWhite: #ffffff;
  --GrayscaleBlack: #000000;
  --en: #7684ff;
  --ko: #f38a62;
  --subYellow: #f8c030;

  /* Violet */
  --violet005: #f9f7ff;
  --violet006: #f4f0ff;
  --violet010: #ece5ff;
  --violet020: #ccc0fd;
  --violet030: #a796fd;
  --violet040: #7c6bff;
  --violet050: #514aff;
  --violet060: #082bf3;
  --violet070: #0028ed;
  --violet080: #0021e4;
  --violet090: #001ddc;
  --violet100: #0014cd;

  /* Primary */
  --primary010: #fff9fa;
  --primary020: #ffedef;
  --primary030: #ffcdd7;
  --primary040: #ff7e8a;
  --primary050: #ff475d;
  --primary060: #ff334b;
  --primary070: #f31237;
  --primary080: #d80039;
  --primary090: #a8002c;

  /* 2dub Color */
  --green010: #F3FBF6;
  --green020: #EAFAEF;
  --green030: #B3F2C7;
  --green040: #59DA88;
  --green050: #00D16C;
  --green060: #00C767;
  --green070: #00AA58;
  --green080: #00934C;
  --green090: #007234;

  /* gradient */
  --gradient45_class: linear-gradient(
    135deg,
    #a95cc9 0%,
    #8b63f5 25.19%,
    #6581ff 51.31%,
    #24c0ff 100%
  );
  --gradientH_class: linear-gradient(
    90deg,
    #a95cc9 0%,
    #8b63f5 25.19%,
    #6581ff 51.31%,
    #24c0ff 100%
  );
  --gradientV_class: linear-gradient(
    180deg,
    #a95cc9 0%,
    #8b63f5 25.19%,
    #6581ff 51.31%,
    #24c0ff 100%
  );

  --fs-h1: 48px;
  --lh-h1: 71px;
  --fs-h2: 34px;
  --lh-h2: 50px;
  --fs-h3: 24px;
  --lh-h3: 36px;
  --fs-h4: 20px;
  --lh-h4: 29px;
  --fs-h5: 18px;
  --lh-h5: 27px;
  --fs-b1: 16px;
  --lh-b1: 24px;
  --fs-b2: 14px;
  --lh-b2: 20px;
  --fs-c1: 12px;
  --lh-c1: 18px;
  --fs-o1: 10px;
  --lh-o1: 15px;
  --fs-t1: 28px;
  --lh-t1: 41px;

  --dropdownMenu_base_white: white;
  --dropdownMenu_hover_base_gray: var(--gray020);
  --dropdownMenu_active_base_gray: var(--gray030);
  --dropdownMenu_default_line: var(--gray040);
  --dropdownMenu_error_line: var(--coral100);
  --dropdownMenu_text_black: var(--black);
  --dropdownMenu_text_gray: var(--gray080);
  --dropdownMenu_disabled_text_gray: var(--gray060);
  --dropdownMenu_text_blue: var(--blue090);
  --dropdownMenu_text_violet: var(--violet050);
  --dropdownMenu_error_text_red: var(--coral100);
  --dropdownMenu_elevation_gray: var(--elevation_06dp);
  --height: 36px;

  --bar_base_white: var(--white);
  --bar_base_gray: var(--gray020);
  --bar_base_deepGray: var(--gray030);
  --bar_line_gray: var(--gray040);
  --bar_text_black: var(--black);
  --bar_text_deepGray: var(--gray080);
  --bar_elevation_lightGray: var(--elevation_03dp);

  --textfield_base_white: var(--white);
  --textfield_default_line: var(--gray040);
  --textfield_hover_line: var(--gray080);
  --textfield_focus_line: var(--violet040);
  --textfield_error_line: var(--coral100);
  --textfield_text: var(--black);
  --textfield_hint_text: var(--gray080);
  --textfield_default_label: var(--gray080);
  --textfield_disable_text: var(--gray080);
  --textfield_icon: var(--gray080);
  --textfield_focus_label: var(--violet040);
  --textfield_error_label: var(--coral100);

  --button_base_blue: var(--blue070);
  --button_hover_base_deepBlue: var(--blue080);
  --button_base_white: var(--white);
  --button_base_lightViolet: var(--violet010);
  --button_base_violet: var(--violet040);
  --button_disabled_base_gray: var(--gray030);
  --button_loading_base_gray: var(--gray030);
  --button_base_lightCoral: var(--coral010);
  --button_base_red: var(--coral090);
  --button_hover_base_lightViolet: var(--violet005);
  --button_hover_base_violet: var(--violet020);
  --button_hover_base_deepViolet: var(--violet050);
  --button_hover_base_lightCoral: var(--coral005);
  --button_hover_base_coral: var(--coral020);
  --button_hover_base_gray: var(--gray020);
  --button_hover_base_red: var(--coral100);
  --button_line_gray: var(--gray040);
  --button_hover_base_lightBlue: var(--blue005);
  --button_hover_line_violet: var(--violet040);
  --button_text_violet: var(--violet050);
  --button_hover_text_violet: var(--violet050);
  --button_disabled_text_gray: var(--gray060);
  --button_text_white: var(--white);
  --button_text_black: var(--black);
  --button_text_blue: var(--blue090);
  --button_text_coral: var(--coral050);
  --button_text_red: var(--coral100);
  --button_text_gray: var(--gray080);
  --button_navigation_gray: var(--elevation_04dp);

  --white: #ffffff;
  --black: #000000;

  --bar_base_white: var(--white);
  --bar_base_gray: var(--gray020);
  --bar_hover_base_gray: var(--gray020);
  --bar_line_gray: var(--gray030);
  --bar_base_lightViolet: var(--violet005);
  --bar_text_violet: var(--violet050);
  --bar_text_black: var(--black);
  --bar_text_deepGray: var(--gray080);
  --button_disabled_text_gray: var(--gray060);

  /* chip */
  --chip_base_white: var(--white);
  --chip_line_gray: var(--gray040);
  --chip_activated_line_violet: var(--violet020);
  --chip_activated_base_violet: var(--violet010);
  --chip_hover_base_violet: var(--violet020);
  --chip_text_black: var(--black);
  --chip_disabled_text_gray: var(--gray060);

  /* checkbox */
  --selectionControl_base_violet: var(--violet040);
  --selectionControl_base_gray: var(--gray060);
  --selectionControl_hover_base_deepViolet: var(--violet050);
  --selectionControl_hover_base_gray: var(--gray070);
}

.not-scroll {
  overflow: hidden;
}

@keyframes loading-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-underline {
  text-decoration: underline;
}

.box + .box {
  margin-left: 4px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}
body
  .os-theme-dark
  > .os-scrollbar
  > .os-scrollbar-track
  > .os-scrollbar-handle {
  background-color: #c1c1c1;
}
body
  .os-theme-dark
  > .os-scrollbar:hover
  > .os-scrollbar-track
  > .os-scrollbar-handle {
  background-color: rgba(0, 0, 0, 0.55);
}

body .plyr__poster {
  background-size: cover;
}

.textarea__autosize {
  width: 100%;
  resize: none;
  padding: 16px 48px 16px 16px;
  outline: 1px solid var(--gray040);
  border-radius: 8px;
  font-size: var(--fs-b1);
  line-height: var(--lh-b1);
  color: var(--black);
}
.textarea__autosize:hover {
  outline: 1px solid var(--black);
}
.textarea__autosize:focus {
  outline: 2px solid var(--violet040);
}
.textarea__autosize::placeholder {
  font-size: var(--fs-b1);
  line-height: var(--lh-b1);
  color: var(--gray080);
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

#page-drawer[hidden] .os-content,
#page-drawer[hidden] .os-scrollbar {
  opacity: 0;
  visibility: hidden;
  transition: none;
}

.os-host.auto__height .os-content {
  height: auto !important;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */
.scrollbar {
  overflow: overlay;
}

/* total width */
body {
  overflow: overlay;
}
::-webkit-scrollbar {
  background-color: rgb(255, 255, 255);
  width: 10px;
  height: 10px;
  z-index: 999999;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 16px;
  border: 2px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

/* scrollbar when scrollbar is hovered */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.55);
  border: 2px solid #fff;
}

/* .scrollbar::-webkit-scrollbar-track:hover  {
  background-color: #e0e0e0;
} */

.DigitRoll {
  display: flex;
  flex-direction: row-reverse;
  overflow-y: hidden;
}

.DigitRoll__Slice {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.DigitRoll__Cell {
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.DigitRoll__Divider {
  padding: 0 2px;
  display: flex;
  align-items: center;
}
