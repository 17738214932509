@font-face {
  font-family: "dub_icon";
  src: url("./font/icon/dub_icon.ttf?y7qbr6") format("truetype"),
    url("./font/icon/dub_icon.woff?y7qbr6") format("woff"),
    url("./font/icon/dub_icon.svg?y7qbr6#dub_icon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "dub_icon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-stopwatch:before {
  content: "\e996";
}
.icon-brackets:before {
  content: "\e997";
}
.icon-scissors:before {
  content: "\e998";
}
.icon-player_play:before {
  content: "\e995";
}
.icon-cam:before {
  content: "\e993";
}
.icon-no_cam:before {
  content: "\e994";
}
.icon-correct:before {
  content: "\e991";
}
.icon-wrong:before {
  content: "\e992";
}
.icon-ad_free:before {
  content: "\e900";
}
.icon-add:before {
  content: "\e901";
}
.icon-announcement:before {
  content: "\e902";
}
.icon-apple:before {
  content: "\e903";
}
.icon-appStore:before {
  content: "\e904";
}
.icon-flip:before {
  content: "\e905";
}
.icon-fold:before {
  content: "\e906";
}
.icon-next:before {
  content: "\e907";
}
.icon-previous:before {
  content: "\e908";
}
.icon-refresh:before {
  content: "\e909";
}
.icon-spread_face:before {
  content: "\e90a";
}
.icon-spread:before {
  content: "\e90b";
}
.icon-top:before {
  content: "\e90c";
}
.icon-undo:before {
  content: "\e90d";
}
.icon-birthday:before {
  content: "\e90e";
}
.icon-book_fill:before {
  content: "\e90f";
}
.icon-book:before {
  content: "\e910";
}
.icon-bookmark_activated:before {
  content: "\e911";
  color: #ff4747;
}
.icon-bookmark_inactive:before {
  content: "\e912";
}
.icon-calendar:before {
  content: "\e913";
}
.icon-call:before {
  content: "\e914";
}
.icon-camera:before {
  content: "\e915";
}
.icon-card:before {
  content: "\e916";
}
.icon-chat:before {
  content: "\e917";
}
.icon-check_button:before {
  content: "\e918";
}
.icon-checked:before {
  content: "\e919";
}
.icon-clapperboard_fill:before {
  content: "\e91a";
}
.icon-classcode:before {
  content: "\e91b";
}
.icon-classes:before {
  content: "\e91c";
}
.icon-close_3px:before {
  content: "\e91d";
}
.icon-close:before {
  content: "\e91e";
}
.icon-congratulation:before {
  content: "\e91f";
}
.icon-copy:before {
  content: "\e920";
}
.icon-delete:before {
  content: "\e921";
}
.icon-deleteUser:before {
  content: "\e922";
}
.icon-desktop:before {
  content: "\e923";
}
.icon-drag:before {
  content: "\e924";
}
.icon-drive:before {
  content: "\e925";
}
.icon-dubbing_face:before {
  content: "\e926";
}
.icon-dubbing:before {
  content: "\e927";
}
.icon-edit:before {
  content: "\e928";
}
.icon-email:before {
  content: "\e929";
}
.icon-evaluate:before {
  content: "\e92a";
}
.icon-exit_fuil_screen:before {
  content: "\e92b";
}
.icon-faq:before {
  content: "\e92c";
}
.icon-feed:before {
  content: "\e92d";
}
.icon-filter:before {
  content: "\e92e";
}
.icon-following:before {
  content: "\e92f";
}
.icon-full_screen:before {
  content: "\e930";
}
.icon-fullView:before {
  content: "\e931";
}
.icon-game:before {
  content: "\e932";
}
.icon-graph:before {
  content: "\e933";
}
.icon-hambergerMenu:before {
  content: "\e934";
}
.icon-help:before {
  content: "\e935";
}
.icon-hide_line:before {
  content: "\e936";
}
.icon-hidepw:before {
  content: "\e937";
}
.icon-home_active:before {
  content: "\e938";
}
.icon-home_inactive:before {
  content: "\e939";
}
.icon-incompletion:before {
  content: "\e93a";
}
.icon-info:before {
  content: "\e93b";
}
.icon-invite:before {
  content: "\e93c";
}
.icon-keyboard:before {
  content: "\e93d";
}
.icon-lab:before {
  content: "\e93e";
}
.icon-language:before {
  content: "\e93f";
}
.icon-lightning:before {
  content: "\e940";
}
.icon-like_face:before {
  content: "\e941";
  color: #ff4747;
}
.icon-like_half:before {
  content: "\e942";
}
.icon-like:before {
  content: "\e943";
}
.icon-link:before {
  content: "\e944";
}
.icon-list:before {
  content: "\e945";
}
.icon-lock:before {
  content: "\e946";
}
.icon-locked_face:before {
  content: "\e947";
}
.icon-locked_fill:before {
  content: "\e948";
}
.icon-login:before {
  content: "\e949";
}
.icon-logout:before {
  content: "\e94a";
}
.icon-map:before {
  content: "\e94b";
}
.icon-matching:before {
  content: "\e94c";
}
.icon-member:before {
  content: "\e94d";
}
.icon-membership_face:before {
  content: "\e94e";
}
.icon-membership:before {
  content: "\e94f";
}
.icon-mic:before {
  content: "\e950";
}
.icon-mission:before {
  content: "\e951";
}
.icon-mode_dark:before {
  content: "\e952";
}
.icon-mode_light:before {
  content: "\e953";
}
.icon-more:before {
  content: "\e954";
}
.icon-more2:before {
  content: "\e955";
}
.icon-movie_fill:before {
  content: "\e956";
}
.icon-myinfo:before {
  content: "\e957";
}
.icon-noti:before {
  content: "\e958";
}
.icon-picture:before {
  content: "\e959";
}
.icon-pin:before {
  content: "\e95a";
}
.icon-play_store:before {
  content: "\e95b";
}
.icon-player_forward:before {
  content: "\e95c";
}
.icon-player_pause:before {
  content: "\e95d";
}
.icon-player_playFace:before {
  content: "\e95e";
}
.icon-player_rewind:before {
  content: "\e95f";
}
.icon-player_stop:before {
  content: "\e960";
}
.icon-policy:before {
  content: "\e961";
}
.icon-post:before {
  content: "\e962";
}
.icon-profile:before {
  content: "\e963";
}
.icon-qr:before {
  content: "\e964";
}
.icon-question:before {
  content: "\e965";
}
.icon-rating:before {
  content: "\e966";
}
.icon-remove:before {
  content: "\e967";
}
.icon-reply:before {
  content: "\e968";
}
.icon-report:before {
  content: "\e969";
}
.icon-restore:before {
  content: "\e96a";
}
.icon-school:before {
  content: "\e96b";
}
.icon-search:before {
  content: "\e96c";
}
.icon-send:before {
  content: "\e96d";
}
.icon-setting:before {
  content: "\e96e";
}
.icon-share:before {
  content: "\e96f";
}
.icon-shortcut:before {
  content: "\e970";
}
.icon-show_line:before {
  content: "\e971";
}
.icon-showpw:before {
  content: "\e972";
}
.icon-speaking_report:before {
  content: "\e973";
}
.icon-speed:before {
  content: "\e974";
}
.icon-starclub_face:before {
  content: "\e975";
}
.icon-starclub:before {
  content: "\e976";
}
.icon-state:before {
  content: "\e977";
}
.icon-storage_box:before {
  content: "\e978";
}
.icon-storage:before {
  content: "\e979";
}
.icon-story:before {
  content: "\e97a";
}
.icon-storytab:before {
  content: "\e97b";
}
.icon-subtitle_ja:before {
  content: "\e97c";
}
.icon-subtitle_ko:before {
  content: "\e97d";
}
.icon-subtitle:before {
  content: "\e97e";
}
.icon-subtract:before {
  content: "\e97f";
}
.icon-terms:before {
  content: "\e980";
}
.icon-thumbup:before {
  content: "\e981";
}
.icon-ticket:before {
  content: "\e982";
}
.icon-timer:before {
  content: "\e983";
}
.icon-together_face:before {
  content: "\e984";
}
.icon-together:before {
  content: "\e985";
}
.icon-tv:before {
  content: "\e986";
}
.icon-unlocked_face:before {
  content: "\e987";
}
.icon-unlocked:before {
  content: "\e988";
}
.icon-upload:before {
  content: "\e989";
}
.icon-version:before {
  content: "\e98a";
}
.icon-video_request:before {
  content: "\e98b";
}
.icon-videobookmark:before {
  content: "\e98c";
}
.icon-videoupload:before {
  content: "\e98d";
}
.icon-volume_veryloud:before {
  content: "\e98e";
}
.icon-zoom_in:before {
  content: "\e98f";
}
.icon-zoom_out:before {
  content: "\e990";
}
